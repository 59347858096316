<template>
  <div class="organ-management-page">
    <el-row>
      <el-col :span="24">
        <el-card shadow="always">
          <el-form :inline="true" :model="form" class="demo-form-inline searchFrom" size="small">
            <el-form-item label="团队名称">
              <el-input v-model="form.name" :clearable="true"></el-input>
            </el-form-item>
            <el-form-item label="团队类别">
              <el-select clearable v-model="form.region">
                <el-option label="全部 " value=""></el-option>
                <el-option label="家庭 " value="JT"></el-option>
                <el-option label="精英 " value="JY"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button icon="el-icon-search" type="primary" :disabled="disabledSearch" @click="getAllTeam(1)">查询</el-button>
            </el-form-item>
          </el-form>
        </el-card>
      </el-col>
    </el-row>
    <el-card shadow="always" class="card">
      <div>
        <el-button type="primary" plain @click="addTeam" icon="el-icon-plus">添加</el-button>
        <el-button type="danger" plain @click="handleDelete" icon="el-icon-delete">删除</el-button>
      </div>
      <div class="table-block">
        <div class="Selectnum">已选中 <span style="color:#5479FF">{{multipleSelection.length}}</span> 条信息</div>
        <el-table :data="tableData" v-loading="loading" ref="multipleTable" @row-click="handleClickTableRow" :header-cell-style="{'text-align': 'center', 'background': '#EEF1FC', 'color': '#656565'}" :cell-style="{'text-align':'center'}" style="width: 100%" @selection-change="handleSelectionChange">
          <el-table-column type="selection" width="50"></el-table-column>
          <el-table-column prop="teamName" label="团队名称"> </el-table-column>
          <el-table-column prop="teamType" label="团队类型"> </el-table-column>
          <el-table-column prop="popNum" label="团队人数"> </el-table-column>
          <el-table-column prop="walletAmount1" label="钱包金额"> </el-table-column>
          <el-table-column prop="createTime" label="创建时间"> </el-table-column>
          <el-table-column label="操作" width="100">
            <template slot-scope="scope">
              <el-button @click="lookFor(scope.row)" type="text" size="small">查看</el-button>
              <el-button @click="updateTeam(scope.row)" type="text" size="small">修改</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="10"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        background>
      </el-pagination>
    </el-card>
    <teamMnagement :info="info" :onlyLook="onlyLook" v-if="showTeam" @closeteamManagement="closeteamManagement"></teamMnagement>
    <teamDetail :info="info" @closeTeamDetail="closeTeamDetail" v-if="showTeamDetail"></teamDetail>
  </div>
</template>

<script>
import teamMnagement from '@/views/customerManagement/component/teamMnagement'
import teamDetail from '@/views/customerManagement/component/teamDetail'
import { postRequest } from '@/api'
export default {
  data() {
    return {
      form: {
        name: '',
        value: '',
      },
      showTeam: false,
      tableData: [],
      multipleSelection: [],
      info: {},
      onlyLook: '',
      rightsList: [],
      showTeamDetail: false,
      pageNo: 1,
      pageSize: 10,
      total: null,
      loading: true,
      disabledSearch: false
    }
  },
  mounted(){
    this.getAllTeam()
    postRequest('/dict/getDictByCategory', {dictionaryCategoryCode: '06'}).then(res => {
      console.log(res, '精英、家庭、SVIP')
      this.rightsList = res.slice(0, 2)
    })
  },
  components: { teamMnagement, teamDetail },
  methods: {
    closeteamManagement() {
      this.showTeam = false
      this.getAllTeam()
    },
    // 点击行选中
    handleClickTableRow(row) {
      this.$refs.multipleTable.toggleRowSelection(row);
    },
    // 选中项
    handleSelectionChange(val) {
      console.log(val);
      this.multipleSelection = val
    },
    addTeam() {
      this.showTeam = true
      this.info = ''
      this.onlyLook = false
    },
    // searchRes(){
    //   let data = {
    //     teamName: this.form.name ? this.form.name : '',
    //     teamDictionaryCode: this.form.region ? this.form.region : ''
    //   }
    //   this.getAllTeam(data)
    // },
    lookFor(val){
      console.log(val)
      this.info = val
      this.onlyLook = true
      this.showTeamDetail = true
    },
    closeTeamDetail(){
      this.showTeamDetail = false
    },
    updateTeam(val){
      this.info = val
      this.onlyLook = false
      this.showTeam = true
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageSize = val
      this.getAllTeam()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.pageNo = val
      this.getAllTeam()
    },
    getAllTeam(no){
      this.loading = true
      this.disabledSearch = true
      let data = {
        teamName: this.form.name ? this.form.name : '',
        teamDictionaryCode: this.form.region ? this.form.region : '',
        pageNo: no ? no : this.pageNo,
        pageSize: this.pageSize
      }
      postRequest('/team/queryTeamInfoPage', data).then(res=>{
        this.total = res.count
        this.disabledSearch = false
        this.loading = false
        console.log(res, '获取团队信息')
        res.data.map(item => {
          if (item.teamDictionaryCode == 'JT') {
            item.teamType = '家庭'
          }
          if (item.teamDictionaryCode == 'JY') {
            item.teamType = '精英'
          }
          item.popNum = item.teamMemberVoList.length
          if (item.walletAmount) {
            let reg = /(\d{1,3})(?=(\d{3})+(\.\d*)?$)/g
            item.walletAmount1 = item.walletAmount
            item.walletAmount1 ='¥ ' + item.walletAmount1.toString().replace(reg,'$&,') + '.00'
          }
        })
        this.tableData = res.data
      }).catch(()=>{
        this.loading = false
        this.disabledSearch = false
      })
    },
    handleDelete(){
      if (this.multipleSelection.length <= 0) {
        this.$message({
          message: '请选择删除项',
          type: 'warning'
        })
      } else {
        // this.multipleSelection.map(item => {
        //   let data = {
        //     id: item.id
        //   }
        //   postRequest('/team/delete', data).then(res=>{
        //     console.log(res)
        //     this.$message({
        //       message: '删除成功',
        //       type: 'success'
        //     })
        //     this.getAllTeam()
        //   })
        // })
        this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          center: true
        }).then(() => {
          postRequest('/team/delete', this.multipleSelection).then(res=>{
            console.log(res)
            this.$message({
              message: '删除成功',
              type: 'success'
            })
            this.getAllTeam()
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.organ-management-page {
  background: transparent;
}
.Selectnum{
  padding:6px 30px;
  border:1px solid #BFCBF4;
  font-size: 12px;
  color:#666;
  border-radius: 5px;
  margin-bottom:10px;
}
</style>

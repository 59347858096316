<template>
  <el-dialog
    title="团队详情"
    :visible.sync="dialogVisible"
    width="60%"
    @close="handleClose">
      <el-card class="header">
        <el-form ref="form" :inline="true" :model="formInline" label-width="80px">
          <el-form-item label="团队名称:">
            <div class="box">{{formInline.teamName}}</div>
          </el-form-item>
          <el-form-item label="团队人数:">
            <div class="box">{{formInline.personAmount}}</div>
          </el-form-item>
          <el-form-item label="钱包金额:">
            <div class="box">{{formInline.walletAmount1}}</div>
          </el-form-item>
        </el-form>
      </el-card>
      <el-card>
        <h3>团队成员信息</h3>
        <el-table
          :header-cell-style="{'text-align': 'center', 'background': '#EEF1FC', 'color': '#656565'}"
          :cell-style="{'text-align':'center'}"
          :data="tableData"
          style="width: 100%">
          <el-table-column
            prop="userName"
            label="客户姓名">
          </el-table-column>
          <el-table-column
            prop="productName"
            label="会员产品">
          </el-table-column>
          <el-table-column
            prop="tel"
            label="手机号">
          </el-table-column>
          <el-table-column
            prop="integral"
            label="积分">
          </el-table-column>
          <el-table-column
            prop="endTime"
            label="会员到期时间">
          </el-table-column>
        </el-table>
      </el-card>
    <!-- <span slot="footer" class="dialog-footer">
    </span> -->
  </el-dialog>
</template>

<script>
import { postRequest } from '@/api'
export default {
  data () {
    return {
      dialogVisible: true,
      formInline:{
      },
      tableData: [],
    }
  },
  props: ['info'],
  mounted(){
    this.getAllTeamDetail()
  },
  methods: {
    handleClose(){
      this.$emit('closeTeamDetail')
    },
    getAllTeamDetail () {
      let data = {
        id: this.info.id
      }
      postRequest('/team/queryTeamDetails', data).then(res=>{
        console.log(res)
        this.formInline = res[0]
        if (this.formInline.walletAmount) {
          let reg = /(\d{1,3})(?=(\d{3})+(\.\d*)?$)/g
          let walletAmount = this.formInline.walletAmount
          this.formInline.walletAmount1 = '¥ ' + walletAmount.toString().replace(reg,'$&,') + '.00'
        }
        this.tableData = res[0].teamMemberVoList
      })
    }
  }
}
</script>

<style scoped>
.el-form--inline {
  display: flex;
  justify-content: space-between;
}
.header {
  margin-bottom: 20px;
  margin-top: -20px;
}
.box {
  border: 1px solid #E5E5E5;
  color: #666666;
  width: 150px;
  border-radius: 5px;
  font-size: 14px;
  height: 32px;
  line-height: 32px;
  padding-left: 10px;
}
h3 {
  margin-top: 0;
}
</style>
<template>
  <el-dialog :close-on-click-modal="false" :show-close="false" :visible.sync="dialogVisible" width="40%" @close="handleClose">
    <el-card>
      <el-form ref="form" v-loading="loading" :model="form" :rules="rules" label-width="90px">
        <p class="title-1">{{title}}</p>
        <el-form-item label="团体名称">
          <el-input :disabled="onlyLook" maxlength="10" placeholder="请输入团体名称" show-word-limit v-model="form.teamName"></el-input>
        </el-form-item>
        <el-form-item label="团体类型" prop="teamDictionaryCode">
          <el-select :disabled="onlyLook" @change="changeType" v-model="form.teamDictionaryCode" placeholder="请选择团体类型">
            <el-option label="家庭" value="JT"></el-option>
            <el-option label="精英" value="JY"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="钱包金额">
          <el-input :disabled="onlyLook" placeholder="请输入钱包金额" @input="inputMoney($event,'walletAmount')" @mousewheel.native.prevent @keyup.native="prevent($event)" v-model="form.walletAmount"></el-input>
        </el-form-item>
        <el-form-item label="团队成员" prop="memteamMemberVoListbers">
          <el-select @change="handleChangeSelect" multiple :disabled="onlyLook" v-model="form.memteamMemberVoListbers" placeholder="请选择家庭成员">
            <el-option v-for="(item, index) in homeList" :key="index" :label="item.customerName" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </el-card>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="sureSubmit()">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { postRequest } from '@/api'
export default {
  data() {
    return {
      dialogVisible: true,
      rightsList: [],
      homeList: [],
      newList: [],
      title: '新建团体',
      obj: {},
      val: [],
      loading: true,
      form: {
        teamName: '',
        teamDictionaryCode: '',
        walletAmount: '',
        memteamMemberVoListbers: [],
        teamMemberVoList: []
      },
      rules: {
        teamDictionaryCode: [
          {required: true, message: '请选择团队类型'}
        ],
        memteamMemberVoListbers: [
          {required: false, message: '请选择团队成员',trigger: 'blur'}
        ]
      }
    }
  },
  props: ['info', 'onlyLook', 'type'],
  mounted(){
    if (this.info) {
      console.log(this.info)
      this.$nextTick(()=>{
        this.info.teamMemberVoList.map(item => {
          this.form.memteamMemberVoListbers.push(item.customId)
          console.log(this.form.memteamMemberVoListbers)
        })
      })
      this.form = this.info
      this.title = '修改团体'
    }
    this.$nextTick(()=>{
      if (this.type) {
        this.form.teamDictionaryCode = this.type
      }
    })
    postRequest('/dict/getDictByCategory', {dictionaryCategoryCode: '06'}).then(res => {
      console.log(res, '精英、家庭、SVIP')
      this.rightsList = res.slice(0,2)
    })
    // 获取家庭成员下拉列表
    postRequest('/customer/queryCustomerInfo', {}).then(res=> {
      console.log(res, '家庭成员下拉列表')
      this.loading = false
      this.homeList = res
    })
  },
  methods: {
    handleClose() {
      this.$emit('closeteamManagement')
    },
    handleChangeSelect(val){
      console.log(val)
      this.newList = val
    },
    inputMoney(value, name) {
      this.form[name] =
        ("" + value) // 第一步：转成字符串
          .replace(/[^\d^\.]+/g, "") // 第二步：把不是数字，不是小数点的过滤掉
          .replace(/^0+(\d)/, "$1") // 第三步：第一位0开头，0后面为数字，则过滤掉，取后面的数字
          .replace(/^\./, "0.") // 第四步：如果输入的第一位为小数点，则替换成 0. 实现自动补全
          .match(/^\d*(\.?\d{0,2})/g)[0] || ""; // 第五步：最终匹配得到结果 以数字开头，只有一个小数点，而且小数点后面只能有0到2位小数
    },
    prevent(e){
      var keynum = window.event ? e.keyCode : e.which;   //获取键盘码
        if (keynum ==189|| keynum==190||keynum == 109 ||keynum == 110 ) {
            this.$message.warning('禁止输入小数以及负数')
            e.target.value = ''
        }
    },
    changeType(val){
      console.log(val)
      this.$forceUpdate()
    },
    sureSubmit(){
      if (this.form.teamDictionaryCode == '') {
        this.$message({
          message: '请完善信息',
          type: 'warning'
        })
      } else {
        let len = '-' + this.form.memteamMemberVoListbers.length
        if (this.newList.length > 0) {
          this.newList.map(i => {
            this.homeList.map(item=>{
              if (item.id == i) {
                let obj = {}
                obj.userId = item.userId
                obj.customId = item.id
                obj.userName = item.customerName
                this.form.teamMemberVoList.push(obj)
                this.form.teamMemberVoList = this.form.teamMemberVoList.slice(len)
              }
            })
          })
        } else {
          this.form.teamMemberVoList = []
        }
        console.log(this.form.teamMemberVoList)
        if (this.info) {
          postRequest('/team/update', this.form).then(res=>{
            console.log(res)
            this.$message({
              message: '修改成功',
              type: 'success'
            })
            this.handleClose()
          })
        } else {
          postRequest('/team/addSave', this.form).then(res=>{
            console.log(res, '添加')
            this.$message({
              message: '添加成功',
              type: 'success'
            })
            this.handleClose()
          })
        }
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.el-select {
  width: 100%;
}
.title-1 {
  color: #444444;
  font-size: 16px;
  margin-bottom: 20px;
  margin-top: 0;
}
.el-card {
  margin-top: -20px;
}
</style>
